"use strict";

// Source: https://www.w3schools.com/js/js_cookies.asp
/* eslint no-unused-vars: "off" */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-params */

var Cookie = {
  set: function set(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=".concat(d.toUTCString());
    document.cookie = "".concat(cname, "=").concat(cvalue, ";").concat(expires, ";path=/");
  },
  get: function get(cname) {
    var name = "".concat(cname, "=");
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  remove: function remove(cname, paramPath, paramDomain) {
    var forceDomain = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var path = paramPath || '/';
    var domain = forceDomain ? paramDomain : paramDomain || window.location.hostname;
    document.cookie = "".concat(cname, "='';path=").concat(path, ";domain=").concat(domain, ";expires=Thu, 01 Jan 1970 00:00:00 GMT");
  }
};